import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-right"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v("我的客服")]), _c('div', {
    staticClass: "operations"
  }, [_c('div', {
    staticClass: "operation-left"
  }, [_vm._m(0), _c('div', {
    staticClass: "left-content"
  }, [_c('p', [_vm._v(_vm._s(_vm.isExternal ? '---' : _vm.data ? _vm.data.name : _vm.mistake.name))])])]), _vm.data ? _c('div', {
    staticClass: "operation-right"
  }, [_vm._m(1), _c('div', {
    staticClass: "right-content"
  }, [_c('p', [_vm._v(_vm._s(_vm.data.phone))]), _c('p', [_vm._v("业务员电话")])])]) : _vm._e()]), _vm._m(2)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "left-img"
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_2.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "right-img"
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_1.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "operations"
  }, [_c('div', {
    staticClass: "operation-right",
    staticStyle: {
      "margin-left": "0"
    }
  }, [_c('span', {
    staticClass: "right-img"
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_1.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "right-content"
  }, [_c('p', [_vm._v("023-88609999")]), _c('p', [_vm._v("售后及投诉电话")])])])]);
}];
export { render, staticRenderFns };