import { AccountStorage } from "@services/account";
var accountStorage = new AccountStorage();
export default {
  components: {},
  data: function data() {
    return {
      showRightList: true,
      isExternal: false,
      companyId: "",
      data: {},
      mistake: {} // 非正式会员
    };
  },
  created: function created() {
    var userId = accountStorage.getCacheUserInfo();
    this.companyId = userId.userId;
    this.isExternal = userId.type == 1 ? true : false;
    this.getStaffInfo();
  },
  methods: {
    // 列表/api/v1/company/getStaffInfo
    getStaffInfo: function getStaffInfo() {
      var _this = this;
      var url = "/pjyy-deepexi-user-center/api/v1/company/getStaffInfo?customerId=".concat(this.companyId);
      this.$getHttpClient().get(url).then(function (res) {
        _this.data = res.data.data.salesman;
        _this.mistake = res.data.data.customerService;
      });
    },
    onSearch: function onSearch() {},
    handleDefault: function handleDefault(props) {
      this.index = props.row.index;
    }
  },
  updated: function updated() {},
  mounted: function mounted() {}
};